import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SideNavigation, IconHome, IconMap, IconCogwheel } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const SideNavExample = () => {
  const [active, setActive] = React.useState('/archive');
  const setActivePage = e => {
    e.preventDefault();
    setActive(e.target.getAttribute('href'));
  };
  return <div style={{
    backgroundColor: '#f5f5f5',
    display: 'grid'
  }}>
    <div style={{
      height: '100%',
      maxWidth: '400px'
    }}>
      <SideNavigation defaultOpenMainLevels={[]} id="side-navigation" toggleButtonLabel="Navigate to page" mdxType="SideNavigation">
        <SideNavigation.MainLevel id="#main-level-1" label="Events">
          <SideNavigation.SubLevel href="/events" active={active === '/events'} id="sub-level-1" label="All events" onClick={setActivePage} />
          <SideNavigation.SubLevel href="/archive" active={active === '/archive'} id="sub-level-2" label="Archive" onClick={setActivePage} />
          <SideNavigation.SubLevel href="/new-event" active={active === '/new-event'} id="sub-level-3" label="Create a new event" onClick={setActivePage} />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel id="main-level-2" label="Events on map">
          <SideNavigation.SubLevel href="/map" active={active === '/map'} id="sub-level-4" label="Map" onClick={setActivePage} />
          <SideNavigation.SubLevel href="/edit-event-locations" active={active === '/edit-event-locations'} id="sub-level-5" label="Edit event locations" onClick={setActivePage} />
          <SideNavigation.SubLevel href="/new-location" active={active === '/new-location'} id="sub-level-6" label="Create a new location" onClick={setActivePage} />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel href="/settings" active={active === '/settings'} id="main-level-3" label="Settings" onClick={setActivePage} />
        <SideNavigation.MainLevel external href="https://tapahtumat.hel.fi/" openInNewTab openInNewTabAriaLabel="Opens in a new tab." openInExternalDomainAriaLabel="Opens a different website." id="main-level-4" label="Tapahtumat.hel.fi" openInExternalDomainAriaLabel="Opens a different website" withDivider />
      </SideNavigation>
    </div>
  </div>;
};
export const SideNavIconsExample = () => {
  const [active, setActive] = React.useState('/archive');
  const setActivePage = e => {
    e.preventDefault();
    setActive(e.target.getAttribute('href'));
  };
  return <div style={{
    backgroundColor: '#f5f5f5',
    display: 'grid'
  }}>
    <div style={{
      height: '100%',
      maxWidth: '400px'
    }}>
      <SideNavigation defaultOpenMainLevels={[]} id="side-navigation-icons" toggleButtonLabel="Navigate to page" mdxType="SideNavigation">
        <SideNavigation.MainLevel icon={<IconHome aria-hidden mdxType="IconHome" />} id="main-level-icons-1" label="Events">
          <SideNavigation.SubLevel href="/events" id="sub-level-icons-1" active={active === '/all-events'} label="All events" onClick={setActivePage} />
          <SideNavigation.SubLevel href="/archive" id="sub-level-icons-2" active={active === '/archive'} label="Archive" onClick={setActivePage} />
          <SideNavigation.SubLevel href="/new-event" id="sub-level-icons-3" active={active === '/new-event'} label="Create a new event" onClick={setActivePage} />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel icon={<IconMap aria-hidden mdxType="IconMap" />} id="main-level-icons-2" label="Events on map">
          <SideNavigation.SubLevel href="/map" id="sub-level-icons-4" active={active === '/map'} label="Map" onClick={setActivePage} />
          <SideNavigation.SubLevel href="/edit-event-locations" id="sub-level-icons-5" active={active === '/edit-event-locations'} label="Edit event locations" onClick={setActivePage} />
          <SideNavigation.SubLevel href="/new-location" id="sub-level-6" active={active === '/new-location'} label="Create a new location" onClick={setActivePage} />
        </SideNavigation.MainLevel>
        <SideNavigation.MainLevel href="/settings" icon={<IconCogwheel aria-hidden mdxType="IconCogwheel" />} id="main-level-icons-3" active={active === '/settings'} label="Settings" onClick={setActivePage} />
        <SideNavigation.MainLevel external href="https://tapahtumat.hel.fi/" openInNewTab openInNewTabAriaLabel="Opens in a new tab." openInExternalDomainAriaLabel="Opens a different website." id="main-level-icons-4" label="Tapahtumat.hel.fi" openInExternalDomainAriaLabel="Opens a different website" withDivider />
      </SideNavigation>
    </div>
  </div>;
};
export const _frontmatter = {
  "slug": "/components/side-navigation",
  "title": "SideNavigation",
  "navTitle": "SideNavigation"
};
const layoutProps = {
  SideNavExample,
  SideNavIconsExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SideNavExample mdxType="SideNavExample" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It is strongly recommended to always include the `}<InternalLink href="/components/navigation" mdxType="InternalLink">{`main (top) navigation component`}</InternalLink>{` in your service.`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`It is allowed to use the side navigation without the top navigation. However, please note that the top navigation includes important features such as language selection and login. If you rely on the side navigation only, you need to provide these functionalities somewhere else in the user interface.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If you use both main (top) and side navigation, do not repeat the same navigation items in both of them.`}</li>
      <li parentName="ul">{`Always position the side navigation to the left-hand side of the page. Keep the navigation in place so the user can always easily locate it.`}</li>
      <li parentName="ul">{`Keep navigation menu link labels clear and concise. Prefer max 1-2 word long labels. Avoid starting multiple menu link labels with the same word.`}</li>
      <li parentName="ul">{`The side navigation allows the use of icons for the first-level links.`}
        <ul parentName="li">
          <li parentName="ul">{`If icons are used, all first-level links should have an icon.`}</li>
          <li parentName="ul">{`Pay attention to the icon choices. The icons should be easily recognisable and not used for decorative purposes only.`}</li>
          <li parentName="ul">{`The second-level links and external links must not include an icon.`}</li>
          <li parentName="ul">{`Labels are always required even if icons are used. Do not create icon-only navigations.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`When including external links in the side navigation, follow guidelines of the `}<InternalLink href="/components/link" mdxType="InternalLink">{`HDS Link component documentation`}</InternalLink>{`.`}</li>
    </ul>
    <h4 {...{
      "id": "when-to-use-a-side-navigation",
      "style": {
        "position": "relative"
      }
    }}>{`When to use a side navigation?`}<a parentName="h4" {...{
        "href": "#when-to-use-a-side-navigation",
        "aria-label": "when to use a side navigation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Do use the Side navigation when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`the service has more than three (3) levels in its navigation hierarchy`}</li>
          <li parentName="ul">{`the user needs to be able to easily scan through and navigate between pages of a specific level (e.g. admin interfaces or documentation sites)`}</li>
          <li parentName="ul">{`you are uncertain of the number of the navigation items and you need room for growth and changes`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Don't use the Side navigation when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`you need space for your content (the side navigation uses a large portion of the content area)`}</li>
          <li parentName="ul">{`your service has a low amount of navigation items and only one level deep navigation hierarchy`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The default side navigation has support for two (2) levels of navigation, icons, external links, and a divider to create logical groups for the navigation items.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SideNavExample mdxType="SideNavExample" />  
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-icons",
      "style": {
        "position": "relative"
      }
    }}>{`With icons`}<a parentName="h4" {...{
        "href": "#with-icons",
        "aria-label": "with icons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Icons are allowed to be used with the main level navigation items and accordions. The second-level navigation items do not have icon support. Also, icons are not visible in the mobile side navigation variant.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SideNavIconsExample mdxType="SideNavIconsExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      